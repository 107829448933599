import {FieldError, FieldErrorsImpl, Merge} from "react-hook-form";

export const formatMoney = (amount: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);

export const formatIsoDateString = (isoDateString: string) => formatDate(new Date(isoDateString));

export const formatDate = (date: Date) => date.toLocaleDateString("en-US", {timeZone: "UTC"});

export const formatError = (error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | Partial<{ type: string | number; message: string; }>) => typeof error.message === "string" ? error.message : null;
