import React from "react";
import ReactDOM from "react-dom/client";
import AuthenticatedRouter from "./components/AuthenticatedRouter";
import {AuthProvider} from "./context/AuthContext";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <AuthenticatedRouter/>
    </AuthProvider>
  </React.StrictMode>
);

