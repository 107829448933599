import React, {useEffect, useState} from "react";
import {ViewContainer} from "../../components/common/view-container/ViewContainer";
import {CustomerData} from "../../types/CustomerData";
import CreditOverview from "../../components/common/credit-overview/CreditOverview";
import {useNavigate} from "react-router-dom";
import {useFetch} from "@fmcc/common-hooks";
import ErrorDialog from "../../components/common/dialog-box/ErrorDialog";
import {errorBodyTemplate} from "../../components/common/dialog-box/ErrorBody";
import {PrimaryButton, SecondaryButton} from "@cxc/react-fds-components";
import {formatDate, formatError} from "../../common-ts/Formats";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

type Props = {
  customerData: CustomerData;
};

export default function MakePrincipalOnlyPayment({customerData}: Props): JSX.Element {
  let navigate = useNavigate();
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [pending, setPending] = useState(false);
  const [status, makePaymentRequest, result] = useFetch<string>();
  const [paymentAgreementStatus, setPaymentAgreementStatus] = useState<boolean>(false);

  useEffect(() => {
    setPending(false);
    switch (status) {
      case "Resolved":
        navigate("/payment-confirmation");
        break;
      case "Pending":
        setPending(true);
        break;
      case "Unauthorized":
        window.fma.logout();
        break;
    }
  }, [status, navigate, result]);

  const createPaymentsRequest = () => {
    if (amount !== undefined) {
      makePaymentRequest(
        `${process.env.REACT_APP_SERVICES_URL}/v1/payments`,
        {
          body: JSON.stringify({amount: amount.toFixed(2)}),
          method: "POST",
          headers: {
            Authorization: `Bearer ${window.fma.CATBundle?.access_token}`,
            "Content-Type": "application/json"
          },
        }
      );
    }
  };

  const schema =
    yup.object().shape({
      amount: yup
        .number()
        .typeError("Please enter a valid amount")
        .lessThan(customerData.principalOutstanding + 0.01, "You entered a payment which exceeds your account balance. Your payment must not exceed your current principal outstanding amount.")
        .moreThan(0, "Please enter a valid amount")
        .required(),
    });

  const {
    setValue,
    formState: {errors},
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const handlePaymentAmountEntered = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amountEntered: number = parseFloat(event.target.value);
    setValue("amount", amountEntered, {shouldValidate: true});
    setAmount(amountEntered);
  }

  return (
    <ViewContainer headerText="Make Principal-Only Payment">
      {(status === "Rejected") &&
          <ErrorDialog body={errorBodyTemplate} title={""} buttonLabel={"Done"} alignment={"left"}/>}
      <div className="lg:p-4 p-2">
        <div className="lg:grid grid-cols-1 lg:grid-cols-2 auto-rows-min gap-x-20">
          <div className="col-start-2 lg:row-start-1 pb-3">
            <h3 className="font-medium pb-2">Credit Overview</h3>
            <CreditOverview
              border={true}
              availableCredit={customerData.availableCredit}
              creditLine={customerData.currentCreditLine}
              currentPrincipalBalance={customerData.principalOutstanding}
              currentDue={customerData.totalPaymentAmount}
              className="w-full"
            />
          </div>
          <div className="col-start-1 lg:row-start-1 sm:pb-4">
            <p className="text-sm font-light">
              If you would like to submit a principal-only payment,
              please enter your payment below. Remember you are enrolled in autopay.
            </p>
            <h3 className="font-medium pt-8">Principal-Only Payment Amount</h3>

            <div
              className={`mt-1 mb-4 relative rounded shadow-sm border p-2 w-full ${errors?.amount ? `border-red-400` : `border-gray-400`}`}
            >
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-black sm:text-sm">$</span>
              </div>
              <input
                id="amountInput"
                type="number"
                min={"0"}
                onChange={(event) =>
                  handlePaymentAmountEntered(event)
                }
                onWheel={(event) => event.currentTarget.blur()}
                className="w-72 mt-1 mb-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full p-.5 pl-5 text-base"
              />
            </div>
            {
              customerData.principalOutstanding === 0 ?
                <p className="text-red-600 text-xs leading-normal">
                  You currently have an account balance of $0.
                  Your account balance must exceed $0 in order to make a payment.
                </p>
                :
                errors?.amount &&
                  <p className="text-red-600 text-xs leading-normal">
                    {formatError(errors.amount)}
                  </p>
            }
          </div>
          <div className="-mt-5 col-start-1 lg:row-start-2 pt-4">
            <h3 className="font-medium">Payment Method</h3>
            <p className="text-sm font-light">
              Bank Account ****{customerData.bankAccountNumber}
            </p>
            <p className="text-sm font-light pt-2 text-ford-gray-I">
              To change your payment method on file, please call 615-315-2023.
            </p>
            <h3 className="font-medium pt-6">Payment Date</h3>
            <p className="text-sm font-light text-black">
              {formatDate(new Date())}
            </p>
          </div>
        </div>
        <p className="text-sm font-light mt-2">
          Payments submitted after 5:00 pm Eastern Time will be processed the following business day.
        </p>

        <div
          className="border-solid border overflow-y-auto max-h-64 mt-4 p-2 leading-none">
          <div className="flex">
            <label className="p-4">
              <input
                type="checkbox"
                color={"#00095B"}
                onChange={() => setPaymentAgreementStatus(!paymentAgreementStatus)}/>
            </label>

            <ul>
              <li className="mt-4 font-light text-sm">By checking the Payment Authorization Agreement check box
                and selecting the Make Payment button, you request and authorize Ford Credit to initiate withdrawals
                by electronic funds transfer from the bank or credit union account (“bank account”) named, funds
                payable to Ford Credit.
              </li>

              <li className="mt-4 text-sm">
                <span className="text-black font-medium">Amount of Transfer:</span>
                <span className="font-light"> This authorization covers the amount indicated above(“principal-only payment”).
                  By submitting a request to make a principal-only payment, you agree the principal-only amount identified will
                be applied to the principal portion of your obligation only and not to any interest/fees and not to
                satisfy any monthly obligations. You agree you are still responsible to satisfy your obligations as set
                forth in your Ford Pro FinSimple Non-Vehicle Financing loan until paid in full. </span>
              </li>

              <li className="mt-4 text-sm">
                <span className="text-black font-medium">Date of Transfer:</span>
                <span className="font-light">Your scheduled principal-only payment will be
                withdrawn from your bank account on the date selected, or if scheduled after 5:00pm EST, the next
                  available business day.</span>
              </li>

              <li className="mt-4 text-sm">
                <span className="text-black font-medium">Pending Principal-Only Payment:</span>
                <span className="font-light"> You may only schedule one
                principal-only payment at a time. You will not
                be able to schedule or make another principal-only payment through Ford Pro FinSimple Non-Vehicle Financing
                until your pending principal-only payment has been withdrawn from your bank account.</span>
              </li>

              <li className="mt-4 text-sm">
                <span className="text-black font-medium">Canceling a Principal-Only Payment:</span>
                <span
                  className="font-light"> Once you schedule a principal-only payment, it cannot be cancelled. </span>
              </li>

              <li className="mt-4 text-sm">
                <span className="text-black font-medium">Non-sufficient Funds:</span>
                <span className="font-light"> You will not be charged a fee by Ford Credit for Ford Pro FinSimple
                  Non-Vehicle Financing transactions unless a principal-only payment is returned for non-sufficient
                  funds and then you may be charged a return fee based on your Ford Pro FinSimple Non-Vehicle Financing
                  loan documents and your state's applicable laws; you authorize its withdrawal. Your bank may charge
                  you fees. A principal-only payment request sent to your bank will be submitted twice before
                  considering the transaction failed. </span>
              </li>

              <li className="mt-4 text-sm">
                <span className="text-black font-medium ">Your Bank Account Only:</span>
                <span className="font-light "> You represent and agree that you have the authority to draw or
                  otherwise transfer funds from your bank account. </span>
              </li>

              <li className="mt-4 text-sm">
                <span className="text-black font-medium">Retain Copy: </span>
                <span className="font-light"> Please retain a copy of this authorization. </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="fds-app flex space-x-8 lg:w-1/2 mt-12 relative h-16 lg:mb-32">
          <PrimaryButton
            loading={pending}
            title="Submit"
            onClick={createPaymentsRequest}
            disabled={!amount || amount > customerData.principalOutstanding || amount <= 0 || !paymentAgreementStatus}
          />
          <SecondaryButton
            className="-mt-2"
            title="Cancel"
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    </ViewContainer>
  );
}
