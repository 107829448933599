import React from "react";
import {StatementOverviewEntry} from "../../../types/statementOverviewEntry";
import {formatMoney} from "../../../common-ts/Formats";

export default function StatementOverview(
  statementOverviewEntry: StatementOverviewEntry
): JSX.Element {
  return (
    <div
      className={`container box-content flex flex-col border-4 border-ford-gray-III lg:w-full mr-2 ${statementOverviewEntry.className} py-2`}
    >
      <table>
        <tbody className={"text-sm"}>
        <tr>
          <td>
            <h4 className="p-2 items-center font-light">Statement Date</h4>
          </td>
          <td>
            <p className="p-2 font-light items-center text-right">
              {statementOverviewEntry.statementDate}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <h4 className="p-2 items-center font-light">Payment Due Date</h4>
          </td>
          <td>
            <p className="p-2 font-light items-center text-right">
              {statementOverviewEntry.paymentDueDate}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <h4 className="p-2 items-center font-light">
              Statement Amount Due
            </h4>
          </td>
          <td>
            <p className="p-2 font-light items-center text-right">
              {formatMoney(statementOverviewEntry.statementAmountDue)}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <h4 className="p-2 items-center font-light font-light">
              Total Balance
            </h4>
          </td>
          <td>
            <p className="p-2 items-center font-light text-right">
              {formatMoney(statementOverviewEntry.totalBalance)}
            </p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}
