import {useLocation, useNavigate} from "react-router-dom";
import NavButton from "./NavButton";

type ActiveButton =
  | "AccountDashboard"
  | "RequestFunds"
  | "MakePayment"
  | "ViewCurrentStatement"
  | "ViewStatementHistory"
  | "TermsAndConditions";

export default function NavigationBar(): JSX.Element {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  let activeButton: ActiveButton;

  switch (pathname) {
    case "/request":
    case "/confirm":
    case "/success":
    case "/unsuccessful":
      activeButton = "RequestFunds";
      break;
    case "/payment":
      activeButton = "MakePayment";
      break;
    case "/statement":
      activeButton = "ViewCurrentStatement";
      break;
    case "/statement/history":
      activeButton = "ViewStatementHistory";
      break;
    case "/terms-conditions":
      activeButton = "TermsAndConditions";
      break;
    case "/":
    default:
      activeButton = "AccountDashboard";
  }

  return (
    <div>
      <div className="col-start-1 row-span-full">
        <div>
          <NavButton
            text={`Account Dashboard`}
            onClick={() => navigate("/")}
            active={activeButton === "AccountDashboard"}
          />
        </div>
        <div>
          <NavButton
            text="Request Funds"
            active={activeButton === "RequestFunds"}
            onClick={() => navigate("/request")}
          />
        </div>
        <div>
          <NavButton
            text={"Make Principal-Only Payment"}
            active={activeButton === "MakePayment"}
            onClick={() => navigate("/payment")}
          />
        </div>
        <div>
          <NavButton
            text="View Statements"
            active={activeButton === "ViewCurrentStatement"}
            onClick={() => {
              navigate("/statement");
            }}
          />
        </div>
        <div>
          <NavButton
            text="View Terms & Conditions"
            active={activeButton === "TermsAndConditions"}
            onClick={() => {
              navigate("/terms-conditions");
            }}
          />
        </div>
        <div>
          <p className="w-full text-sm text-left lg:text-left font-light pt-4 ml-1">
            For customer support, please contact us at 615-315-2023.
          </p>
        </div>
      </div>
    </div>
  );
}
