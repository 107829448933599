import {Status} from "@fmcc/common-hooks/dist/hooks/useFetch/useFetch";
import {Navigate} from "react-router-dom";
import Loader from "../../components/common/loader/Loader";
import {ViewContainer} from "../../components/common/view-container/ViewContainer";
import {CustomerData} from "../../types/CustomerData";

type Props = {
  status: Status;
  children: JSX.Element;
  customerData: CustomerData | undefined;
};

export default function LoadCustomerData({status, children, customerData}: Props) {

  if (customerData?.message?.includes("No customer data found")) {
    return <Navigate to="/account-link"/>;
  } else if (customerData === undefined) {
    return <Navigate to="/login-error"/>;
  }

  switch (status) {
    case "Resolved":
      return children;
    default:
      return (
        <ViewContainer>
          <Loader/>
        </ViewContainer>
      );
  }
}
