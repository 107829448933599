import CreditOverview from "../../components/common/credit-overview/CreditOverview";
import StatementOverview from "../../components/common/statement-overview/StatementOverview";
import {useNavigate} from "react-router-dom";
import {CustomerData} from "../../types/CustomerData";
import {ViewContainer} from "../../components/common/view-container/ViewContainer";
import {formatIsoDateString, formatMoney} from "../../common-ts/Formats";
import {PrimaryButton} from "@cxc/react-fds-components";
import AutoPayBox from "./AutoPayBox";


type Props = {
  customerData: CustomerData;
};

export default function AccountDashboard({customerData}: Props): JSX.Element {
  const navigate = useNavigate();

  return (
    <ViewContainer headerText="Account Dashboard">
      <div className="lg:grid grid-cols-1 lg:grid-cols-2 auto-rows-min gap-y-5 gap-x-28 text-base">
        <div
          className="container h-44 relative sm:col-start-1 row-start-3 lg:col-start-2 lg:row-start-2 bg-ford-gray-V lg:w-full">
          <div className="flex ">
            <p className="p-6 font-light items-center text-left">If you would like to submit a principal-only payment,
              click the button below.</p>
          </div>
          <div
            className="fds-app absolute bottom-0 p-6 w-full flex items-center justify-evenly bg-ford-gray-V mb-2 ">
            <PrimaryButton
              title={"Make Principal-Only Payment"}
              onClick={() => navigate("/payment")}
            />
          </div>
        </div>
        <div
          className="container h-44 relative col-start-1 lg:row-start-2 bg-ford-gray-V lg:w-full  mr-6">
          <div className="flex">
            <p className="p-6 font-light items-center text-left bg-ford-gray-V">
              You have{" "}
              <span className="font-bold">
                {formatMoney(customerData.availableCredit)}
              </span>{" "}
              in available credit from your line of{" "}
              <span className="font-bold">
                {formatMoney(customerData.currentCreditLine)}
              </span>.
            </p>
          </div>
          <div className="absolute bottom-0 p-6 fds-app w-full flex items-center justify-evenly mb-2">
            <PrimaryButton
              title="Request Funds"
              onClick={() => navigate("/request")}
            />
          </div>
        </div>
        <AutoPayBox className={"col-start-1 row-start-3 col-span-2 bg-ford-gray-V"}
                    paymentAmount={customerData.totalPaymentAmount}
                    paymentDate={customerData.paymentDueDate}/>
        <div className="col-start-1 row-start-4">
          <h3 className="font-medium py-4">Credit Overview</h3>
          <CreditOverview
            border={true}
            availableCredit={customerData.availableCredit}
            creditLine={customerData.currentCreditLine}
            currentPrincipalBalance={customerData.principalOutstanding}
            currentDue={customerData.totalPaymentAmount}
            className={"w-full"}
          />
        </div>
        <div className="col-start-2 row-start-4">
          <h3 className="font-medium py-4">Statement</h3>
          <StatementOverview
            statementDate={formatIsoDateString(customerData.statementDate)}
            paymentDueDate={formatIsoDateString(customerData.paymentDueDate)}
            statementAmountDue={customerData.statementAmountDue}
            totalInterestPaid={customerData.totalInterestAmount}
            totalBalance={customerData.payoff}
          />
        </div>
      </div>
      <div className="font-light text-xs mt-4 text-ford-gray-I">
        <p>Your current interest rate is {customerData.annualPercentageRate}%. Your year to date total interest
          paid is ${customerData.totalInterestAmount}.
          <br/>Your interest rate may be subject to change.
          Review your Ford Pro FinSimple Non-Vehicle Financing loan documents for details.</p>
      </div>
      <hr className="mt-6  border-t-2 row-span-full p-2 border-gray-300 "/>
      <div className="p-4 flex flex-col">
        <h3 className="font-medium ">Account Details</h3>
        <p className="font-light text-sm ">
          {customerData.businessName}
          <br/>
          {`${customerData.addressLineOne} ${
            customerData.addressLineTwo
              ? ", " + customerData.addressLineTwo
              : " "
          }, 
              ${customerData.addressCity}, ${customerData.addressState}, ${
            customerData.addressZip
          }`}
        </p>
        <h3 className="font-medium mt-6 ">Account Number</h3>
        <p className="font-light text-sm">{customerData.dealerFinanceAccountNumber}</p>
      </div>
    </ViewContainer>
  );
}
