export const removeFileByIndex = (files: File[], index: number) => {
  files.splice(index, 1);
  return files;
};

export function getTotalSize(newFileArray: File[]) {
  if (newFileArray.length === 0) {
    return 0;
  }
  return newFileArray
    .map((file) => file.size)
    .reduce((previousSize, currentSize) => previousSize + currentSize);
}

