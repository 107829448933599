import {useStatements} from "../../context/StatementContext";
import StatementDropdown from "../../components/StatementDropdown";
import React from "react";
import {ViewContainer} from "../../components/common/view-container/ViewContainer";

export default function CurrentStatement(): JSX.Element {
  const {statements} = useStatements();
  // On button click dropdown link to call backend for statement pdf
  // Style page to match format of other pages

  return (
    <ViewContainer headerText="View Statements">
      {statements ? statements.map(statement =>
          <ul className={"list-none px-4 mx-2"}>
            <StatementDropdown key={statement.toString()} date={statement.toString()}/>
          </ul>
        ) :
        <p>You don't have any statements...</p>
      }
    </ViewContainer>
  )
}
