import React from "react";
import {CreditOverviewEntry} from "../../../types/creditOverviewEntry";
import {formatMoney} from "../../../common-ts/Formats";

export default function CreditOverview(
  creditOverviewEntry: CreditOverviewEntry
): JSX.Element {
  return (
    <div
      className={`container flex flex-col lg:text-base ${
        creditOverviewEntry.className
      } ${
        creditOverviewEntry.border ? " border-4 border-ford-gray-III box-content" : ""
      } py-2`}
    >
      <table
        className={`${
          !creditOverviewEntry.border ? "border-ford-gray-III border-4" : ""
        }`}
      >
        <tbody className={"text-sm font-light"}>
        <tr>
          <td>
            <h4 className={"p-2 items-center"}>Credit Line</h4>
          </td>
          <td>
            <p className="p-2 items-center text-right">
              {formatMoney(creditOverviewEntry.creditLine)}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <h4 className="p-2 items-center">
              Available Funds For Draw
            </h4>
          </td>
          <td>
            {" "}
            <p className="p-2 items-center text-right">
              {formatMoney(creditOverviewEntry.availableCredit)}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <h4
              className={
                "p-2 items-center whitespace-nowrap  "
              }
            >
              Current Principal Outstanding
            </h4>
          </td>
          <td>
            <p className="p-2 items-center text-right">
              {formatMoney(creditOverviewEntry.currentPrincipalBalance)}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <h4
              className={
                "p-2 items-center"
              }
            >
              Current Due
            </h4>
          </td>
          <td>
            <p className="p-2 items-center text-right">
              {formatMoney(creditOverviewEntry.currentDue)}
            </p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}
