import React from "react";
import {PrimaryButton} from "@cxc/react-fds-components";
import CardContainer from "../../components/common/card-container/CardContainer";

export default function LoginError(): JSX.Element {
  return (
    <>
      <div className="m-8">
        <h1 className="ml-2 mb-6 mt-8 font-light text-4xl">Ford Charging Infrastructure</h1>
        <CardContainer>
          <h2 className="font-regular text-headerTextCustom pt-2 pb-8">Sorry, something went wrong</h2>
          <p className="font-regular text-black text-base pb-12">
            There is a problem logging into your account. Please try again. If you continue to experience problems,
            please contact us at
            <span className="font-extrabold"> 616-315-2023</span>.
          </p>
          <div className="fds-app w-full pb-14">
            <PrimaryButton
              className="pr-6"
              title="Login"
              onClick={() => {
                window.fma.login()
              }}
            />
          </div>
        </CardContainer>
      </div>
    </>
  );
}
