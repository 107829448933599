import {ViewContainer} from "./common/view-container/ViewContainer";

type Props = {
  forDashBoard?: boolean;
};

const template = (forDashBoard: boolean) => {
  return (
    <div
      className={`overflow-y-auto  ${
        forDashBoard ? "max-h-screen" : "bg-gray-200 max-h-60"
      } m-5 py-3 `}
    >
      <h2 className="text-base font-bold mx-5">
        Ford Pro FinSimple Non-Vehicle Financing Terms & Conditions
      </h2>
      <ol className="list-decimal mx-5 text-sm">
        <li className="font-bold mx-5 pt-4">
          Introduction
          <p className="-ml-5 font-normal">
            The Ford Pro FinSimple Non-Vehicle Financing website ("Ford Pro FinSimple
            Non-Vehicle Financing") allows you to register with Ford Credit to
            view account information relating to your Ford Pro FinSimple Non-Vehicle Financing
            loan, request funds, make payments and manage your
            Ford Pro FinSimple Non-Vehicle Financing loan account(s) online. As used
            in this Agreement, the terms, "Ford Credit," "we," "us," and "our"
            refer to Ford Motor Credit Company LLC and its subsidiaries
            (collectively "Ford Credit") and "you" or "your" refers to the
            company or business registered for or using Ford Pro FinSimple Non-Vehicle Financing.
            If you have an eligible account, Ford Pro FinSimple Non-Vehicle Financing gives you
            online access to: account information, like account balances and payment histories; and
            account services, like requesting funds from your charging
            infrastructure loan as set forth in your Ford Pro FinSimple Non-Vehicle Financing
            loan documents. Any personal information you submit or is collected by Ford Credit
            related to your use of Ford Pro FinSimple Non-Vehicle Financing will be handled as
            described in the Privacy Policy posted on the Ford Credit website (currently at
            www.ford.com/finance). Please review the Privacy Policy before
            enrolling in Ford Pro FinSimple Non-Vehicle Financing.
          </p>
        </li>
        <li className="font-bold mx-5 pt-4">
          General Terms
          <p className="-ml-5 font-normal">
            You must agree to these terms and conditions ("this Agreement") to
            use Ford Pro FinSimple Non-Vehicle Financing. When you use or access Ford Pro FinSimple Non-Vehicle
            Financing, you agree to the terms of this
            Agreement, which we may update from time to time by posting the
            updated terms on this site or by sending you notice by email or
            regular mail. You can find our contact information at the top of
            your Ford Pro FinSimple Non-Vehicle Financing homepage.
          </p>
          <br/>
          <p className="-ml-5 font-normal underline ">
            For complete details about your Ford Pro FinSimple Non-Vehicle Financing
            loan, please review your Ford Pro FinSimple Non-Vehicle Financing loan
            documents.
          </p>
        </li>
        <li className="font-bold mx-5 pt-4">
          Account Eligibility
          <p className="-ml-5 font-normal">
            You must have an active loan with Ford
            Credit and create a Ford account at
            <span>
              {" "}
              <a
                className="normal-link"
                href="https://www.ford.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.ford.com
              </a>
            </span>{" "}
            to use Ford Pro FinSimple Non-Vehicle Financing.
          </p>
        </li>
        <li className="font-bold mx-5 pt-4">
          Accuracy of Site Information
          <p className="-ml-5 font-normal">
            ALL INFORMATION AND SERVICES PROVIDED IN THE FORD PRO FINSIMPLE
            NON-VEHICLE FINANCING SYSTEM ARE OFFERED ON AN "AS IS" BASIS, WITH NO
            WARRANTY OF ANY KIND – WHETHER EXPRESS, IMPLIED, OR STATUTORY –
            INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR THE IMPLIED
            WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE OR MERCHANTABILITY.
            THIS DOES NOT AFFECT THOSE WARRANTIES WHICH ARE INCAPABLE OF
            EXCLUSION, RESTRICTION, OR MODIFICATION UNDER THE LAWS APPLICABLE TO
            THIS AGREEMENT. We will make reasonable efforts to provide accurate
            and current information; however, Ford Pro FinSimple Non-Vehicle Financing
            may contain errors and may not always be current due
            to processing delays or other reasons. By using the information
            provided in Ford Pro FinSimple Non-Vehicle Financing, you acknowledge
            that neither we nor our third-party service providers make any
            guarantee that the services offered will be timely, uninterrupted,
            secure, or error free. You understand and agree that your access and
            use of Ford Pro FinSimple Non-Vehicle Financing are at your sole risk.
          </p>
        </li>
        <li className="font-bold mx-5 pt-4">
          Security and User Responsibility
          <p className="-ml-5 font-normal">
            You understand and accept the risks associated with using the
            internet, such as risks of security breaches, transmission errors,
            and service interruptions. Information sent over the internet may
            not be secure. You understand and accept the risk that a third party
            may intercept the information you send to us online or in our
            response(s).
          </p>
          <p className="-ml-5 font-normal">
            If you do not receive an email notifying you that your statement is
            ready or the statement is not shown in Ford Pro FinSimple Non-Vehicle Financing,
            you are still required to make your payment on or by
            your scheduled due date, as set forth in your Ford Pro FinSimple
            Non-Vehicle Financing loan documents. You must use the email/username and
            password associated with your Ford Account when you register to use
            Ford Pro FinSimple Non-Vehicle Financing. You must keep your username and
            password secure and change your password regularly. Notify us
            immediately if there is an unauthorized disclosure or use of your
            username or password. Actions taken using your username and password
            will be treated as approved by you. We are not responsible for your
            errors or negligent use of Ford Pro FinSimple Non-Vehicle Financing.
          </p>
        </li>

        <li className="font-bold mx-5 pt-4">
          Electronic Payments
          <p className="-ml-5 font-normal">
            Depending on your account status and type of Ford Pro FinSimple Non-Vehicle Financing loan,
            you may be able to authorize the electronic payment of principal-only amounts (as set forth
            in your Ford Pro FinSimple Non-Vehicle Financing loan documents) using Ford Pro FinSimple
            Non-Vehicle Financing. If you use Ford Pro FinSimple Non-Vehicle Financing to do so,
            you consent and agree to the following terms.
          </p>

          <ol className="list-alphabetical m-2">
            <li className="mt-4 font-normal font-bold">
              You represent and agree that you have the authority to draw or otherwise transfer funds from the
              bank account number(s) you have or will provide to us. If you have previously provided Ford Credit
              authorization via an electronic funds transfer agreement to process payments, you authorize us
              to present you with that same bank account information for use in Ford Pro FinSimple Non-Vehicle
              Financing. In addition to any previous authorization, you authorize us to draw or otherwise
              transfer funds from that bank account in the amounts you authorize in Ford Pro FinSimple
              Non-Vehicle Financing.
            </li>

            <li className="pt-4 font-normal">
              If after you enroll in Ford Pro FinSimple Non-Vehicle Financing, we determine that your bank account
              number is not a valid source of funds, then we may contact you and you may be restricted from making
              principal-only payments through Ford Pro FinSimple Non-Vehicle Financing.
            </li>

            <li className="pt-4 font-normal">
              You may only schedule one principal-only payment at a time. You will not be able to schedule or
              make another principal-only payment through Ford Pro FinSimple Non-Vehicle Financing until
              your pending principal-only payment has been withdrawn from your bank account.
            </li>

            <li className="pt-4 font-normal">
              Principal-only payments will be credited and scheduled for processing within two (2) business
              days from the date of authorization.
            </li>

            <li className="pt-4 font-normal">
              Once a principal-only payment is scheduled, it cannot be cancelled.
            </li>

            <li className="pt-4 font-normal">
              You will not be charged a fee by Ford Credit for Ford Pro FinSimple Non-Vehicle Financing
              transactions unless a payment is returned for non-sufficient funds and then you may be
              charged a return fee based on your Ford Pro FinSimple Non-Vehicle Financing loan documents
              and your state's applicable laws. Your bank may charge you fees. Payment requests sent to
              your bank will be submitted twice before considering the transaction failed.
            </li>
          </ol>
        </li>


        <li className="font-bold mx-5 pt-4">
          Marketing and Communications
          <p className="-ml-5 font-normal">
            By enrolling in Ford Pro FinSimple Non-Vehicle Financing, you expressly
            consent and agree that Ford Credit, our affiliates, agents, and
            service providers may use written, electronic, or verbal means to
            contact you regarding your account, and/or your use of Ford Pro FinSimple
            Non-Vehicle Financing. This consent includes, but is not limited
            to, contact by manual calling methods, prerecorded or artificial
            voice messages, text messages, emails, and/or automatic telephone
            dialing systems. You agree that Ford Credit, our affiliates, agents,
            and service providers may use any email address or any telephone
            number you provide now or in the future including a number for a
            cellular phone or other wireless device, regardless of whether you
            incur charges as a result. You agree that Ford Credit, our
            affiliates, agents, and service providers may monitor and record
            telephone calls between us to assure the quality of our service or
            for other reasons.
          </p>
          <p className="-ml-5 font-normal">
            Additionally, by enrolling in Ford Pro FinSimple Non-Vehicle Financing,
            you agree to receive marketing emails from Ford Credit regarding
            promotions or special offers. If you do not wish to receive
            promotional marketing emails from Ford Credit, you may update your
            notification preferences by emailing a request to FPFSCHRG@ford.com.
          </p>
          <p className="-ml-5 font-normal">
            Our website may contain links to independent websites. We have no
            control over or responsibility for the content of any independent
            third-party site hyperlinked to Ford Pro FinSimple Non-Vehicle Financing.
            Any such links are for your convenience and should not be construed
            as an endorsement by us.
          </p>
        </li>
        <li className="font-bold mx-5 pt-4">
          Consent to Electronic Communications
          <p className="-ml-5 font-normal">
            You agree to receive electronically, and can receive electronically,
            all required disclosures, agreements, documents, notices, and
            communications that we provide in connection with your account(s)
            ("Electronic Communications"). These Electronic Communications will
            be provided to you via the email address you provide in your Ford Pro
            FinSimple Non-Vehicle Financing registration (as updated by you) or
            via Ford Credit websites, mobile applications, or mobile websites.
          </p>
        </li>
      </ol>
      <div className="mx-5 pt-4 text-sm">
        <p>
          You acknowledge you have a valid email address and that your computer,
          mobile, tablet or similar device used to access Ford Pro FinSimple Non-Vehicle
          Financing has internet access, a current browser and computer
          software that are capable of receiving, accessing, displaying, and
          either printing or storing Electronic Communications. Hardware and
          software requirements include an internet connection; access to a
          device (e.g., computer, smartphone, mobile device, tablet, etc.); a
          current or recent version of Edge, Safari, Firefox, or Chrome with an
          operating system capable of supporting such browsers; and software
          that enables you to open and view .pdf files.
        </p>

        <p>
          You can update your contact information in Ford Pro FinSimple Non-Vehicle Financing or
          by calling Ford Credit Customer Service Center. You
          acknowledge and agree that if we send you an Electronic Communication,
          but you do not receive it because your contact information is
          incorrect or out of date, we will be deemed to have provided that
          Electronic Communication to you.
        </p>
      </div>
    </div>
  );
};
export default function TermsAndConditions({
                                             forDashBoard,
                                           }: Props): JSX.Element {

  return (
    <>
      {forDashBoard ? (
        <ViewContainer headerText="Terms & Conditions">
          {template(true)}
        </ViewContainer>
      ) : (
        template(false)
      )}
    </>
  );
}
