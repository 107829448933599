import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useFetch} from "@fmcc/common-hooks";
import checkmark from "../../assets/Checkmark.svg";
import CreditOverview from "../../components/common/credit-overview/CreditOverview";
import {CustomerData} from "../../types/CustomerData";
import {ViewContainer} from "../../components/common/view-container/ViewContainer";
import {formatMoney} from "../../common-ts/Formats";
import {PrimaryButton} from "@cxc/react-fds-components";
import ErrorDialog from "../../components/common/dialog-box/ErrorDialog";
import {errorBodyTemplate} from "../../components/common/dialog-box/ErrorBody";

type FundState = {
  payableTo: string;
  amount: number;
  documents: File[];
};

type Props = {
  customerData: CustomerData;
};

export default function ConfirmRequest({customerData}: Props) {
  let navigate = useNavigate();
  const [pending, setPending] = useState(false);
  const [status, addRequestFunds, result] = useFetch<string>();
  const {state: confirmProps}: { state: FundState } = useLocation();
  let dateObj = new Date();
  let month = dateObj.getUTCMonth() + 1; //months from 1-12
  let day = dateObj.getUTCDate();
  let year = dateObj.getUTCFullYear();

  let newdate = month + "/" + day + "/" + year;

  useEffect(() => {
    switch (status) {
      case "Resolved":
        navigate("/success");
        break;
      case "Pending":
        setPending(true)
        break;
      case "Unauthorized":
        window.fma.logout()
        break;
    }
  }, [status, navigate, result]);
  const createRequestFunds = () => {
    let formData = new FormData();

    confirmProps.documents.forEach((doc) => {
      formData.append("documents", doc);
    });

    formData.append("amount", confirmProps.amount.toString().replace(",", ""));
    if (confirmProps.payableTo) {
      formData.append("payableTo", confirmProps.payableTo);
    }

    const versionOfAPI = "v3";

    addRequestFunds(
      `${process.env.REACT_APP_SERVICES_URL}/${versionOfAPI}/nva/fundsRequest`,
      {
        body: formData,
        method: "POST",
        headers: {
          Authorization: `Bearer ${window.fma.CATBundle?.access_token}`,
        },
      }
    );
  };

  return (
    <ViewContainer headerText="Confirm Request Funds">
      {(status === "Rejected") &&
          <ErrorDialog body={errorBodyTemplate} title={""} buttonLabel={"Done"} alignment={"left"}
                       onClick={() => navigate("/request")}/>}
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:auto-rows-min gap-y-5 gap-x-10 lg:p-4 p-2">
        <div>
          <label htmlFor="todaysDate" className="font-med">
            Request Date
          </label>
          <p id="todaysDate" className="py-1 mb-4 font-light">
            {newdate}
          </p>
          <label htmlFor="payableTo" className="font-med">
            Payable to
          </label>
          <p className="py-1 mb-4 font-light">
            {confirmProps.payableTo}
          </p>
          <label htmlFor="amountInput" className="font-med">
            Amount Requested
          </label>
          <p className="py-1 mb-4 font-light">
            {formatMoney(confirmProps.amount)}
          </p>
          {confirmProps.documents.length > 0 &&
              <label htmlFor="documentUpload" className="font-med">
                  Supporting Documents
              </label>
          }
          {confirmProps.documents.map((file, index) => {
            return (
              <div className="flex flex-col-reverse lg:flex-col" key={index}>
                <div className="flex items-center invisible lg:visible w-full">
                  <p className="lg:text-xs px-2 w-min whitespace-nowrap font-light flex items-center p-2">
                    <img src={checkmark} alt="Check Box" className="h-4"/>
                    {truncate(file.name, 34)}
                  </p>
                </div>
                <div className="text-center visible lg:invisible">
                  <p
                    className="flex flex-row lg:flex-row lg:text-xs text-xxs px-2 border border-gray-300 w-min whitespace-nowrap font-light p-2">
                    <img src={checkmark} alt="Check Box" className="h-3"/>
                    {truncate(file.name, 25)}
                  </p>
                </div>
              </div>
            );
          })}
          <div className="fds-app flex space-x-10 mt-8 lg:w-1/2 m-2 relative h-16 lg:mb-32">
            <PrimaryButton
              loading={pending}
              className="pr-6"
              title="Confirm"
              onClick={createRequestFunds}
            />
            <PrimaryButton
              mode="dark"
              title="Cancel"
              onClick={() => navigate("/request")}
            />
          </div>
        </div>
        <div className="mx-auto">
          <h3 className="font-medium text-base">Credit Overview</h3>
          <CreditOverview
            border={false}
            availableCredit={customerData.availableCredit}
            creditLine={customerData.currentCreditLine}
            currentPrincipalBalance={customerData.principalOutstanding}
            currentDue={customerData.totalPaymentAmount}
            className="lg:h-1/4 lg:w-4/5"
          />
        </div>
      </div>
    </ViewContainer>
  );
}

function truncate(str: string, maxLength: number) {
  return str.length > maxLength ? str.substr(0, maxLength - 1) + "..." : str;
}
