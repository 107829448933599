import {useFetch} from "@fmcc/common-hooks";
import {Status} from "@fmcc/common-hooks/dist/hooks/useFetch/useFetch";
import React, {createContext, useContext, useEffect} from "react";
import {useAuth} from "./AuthContext";

export type Statement = {
  file: string;
  statementDate: Date;
  customerId: string;
  fileType: string
};

type StatementContext = {
  statementStatus: Status;
  statements: Statement[] | null;
};

const statementContext = createContext<StatementContext>({
  statementStatus: "NotAsked",
  statements: null,
});

type StatementProviderProps = {
  children: React.ReactNode;
};

export function StatementProvider({children}: StatementProviderProps) {
  const statements = useProvidedStatements();
  return (
    <statementContext.Provider value={statements}>
      {children}
    </statementContext.Provider>
  );
}

export function useStatements() {
  return useContext(statementContext);
}

function useProvidedStatements() {
  const [statementStatus, fetchStatementData, statements] = useFetch<Statement[]>();
  const {isAuthenticated} = useAuth();

  useEffect(() => {
    if (statementStatus === "NotAsked" && isAuthenticated) {
      fetchStatementData(`${process.env.REACT_APP_SERVICES_URL}/v2/nva/statement`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.fma.CATBundle?.access_token}`,
        },
      });
    }
  }, [isAuthenticated, statementStatus, fetchStatementData]);

  return {statementStatus: statementStatus, statements: statements};
}
