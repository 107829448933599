import triangleExclamation from "../../../assets/triangle-exclamation.svg";
import React from "react";

export const errorBodyTemplate =
  <div>
    <div className={"flex -mt-10"}>
        <span>
             <img src={triangleExclamation} alt="" className="w-14"/>
        </span>
      <span className={"pt-2 pl-2 text-xlCustom text-error-red"}>Request Not Complete</span>
    </div>
    <div className="py-4 h-1 mb-4">
      <div className="w-full border-t border-gray-500"></div>
    </div>
    <div>
        <span className={"text-ford-gray-I"}>
          There is a problem submitting your request. If you would like to speak with a Ford
        Credit representative,
        please call us at </span> 616-315-2023.
    </div>

  </div>;
