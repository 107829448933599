import {Navigate, useNavigate} from "react-router-dom";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useDropzone} from "react-dropzone";
import {CustomerData} from "../../types/CustomerData";
import {ViewContainer} from "../../components/common/view-container/ViewContainer";
import {formatDate, formatError} from "../../common-ts/Formats";
import {getTotalSize, removeFileByIndex} from "../../common-ts/FileHelper";
import {PrimaryButton, Radio, RadioGroup, SecondaryButton} from "@cxc/react-fds-components";
import CreditOverview from "../../components/common/credit-overview/CreditOverview";

type Props = {
  customerData: CustomerData;
};

export default function RequestFunds({customerData}: Props): JSX.Element {
  let navigate = useNavigate();
  const [payableTo, setPayableTo] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [documents, setDocuments] = React.useState<File[]>([]);
  const [confirmationRedirect, setConfirmationRedirect] = React.useState(false);
  const {getRootProps, getInputProps} = useDropzone({
    onDrop: (selectedDocument) => {
      fileChangeHandler(selectedDocument);
    },
  });

  const schema = yup.object().shape({
    payableTo: yup
      .string()
      .required("An option must be selected."),
    amount: yup
      .number()
      .typeError("Please enter a valid amount")
      .lessThan(customerData.availableCredit, "Cannot exceed available credit")
      .moreThan(0, "Please enter a valid amount")
      .required(),
    documents: yup.mixed().when("payableTo", {
      is: "My Business",
      then: () => yup.mixed().required("You must include supporting documents")
    }),
  })


  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
    setError,
    control,
    watch,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const amountChangeHandler = (value: string) => {
    setValue("amount", value, {shouldValidate: true});
    setAmount(value);
  };

  const convertBytesToMb = (bytes: number) => {
    const megaBytes = bytes / 1024 / 1024;
    if (megaBytes < 0.01) {
      return "0.01";
    }
    return megaBytes.toFixed(2);
  };

  const fileChangeHandler = (files: File[]) => {
    const newFileArray = files.concat(documents);
    const totalFileSizes = getTotalSize(newFileArray);
    if (totalFileSizes < 25 * 1024 * 1024 && newFileArray.length !== 0) {
      // @ts-ignore
      errors.documents = false;
      setDocuments(newFileArray);
      setValue("documents", newFileArray, {shouldValidate: true});
    } else {
      setError("documents", {
        type: "file size limit exceeded",
        message:
          "Last attachment exceeds file size limit. Total file size of all documents must be 25MB or less.",
      });
    }
  };

  const removeFile = (index: number) => {
    const newFileArray = removeFileByIndex([...documents], index);
    if (newFileArray.length === 0) {
      setDocuments([]);
      setValue("documents", undefined, {shouldValidate: true});
    } else {
      setDocuments(newFileArray);
      setValue("documents", newFileArray, {shouldValidate: true});
    }
  };
  let files = documents.map((file: File, index: number) => (
    <li className="text-sm" key={index}>
      {file.name} - {convertBytesToMb(file.size)} MB -{" "}
      <p
        className="underline inline cursor-pointer"
        onClick={() => removeFile(index)}
      >
        Remove File
      </p>
    </li>
  ));

  if (confirmationRedirect) {
    return (
      <Navigate
        to={"/confirm"}
        state={{payableTo, amount, documents}}
      />
    );
  }


  return (
    <ViewContainer headerText="Request Funds">
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:auto-rows-min gap-y-5 gap-x-10 p-4">
        <form>
          <label htmlFor="todaysDate" className="font-med">
            Request Date
          </label>
          <p id="todaysDate" className="mt-1 mb-5 font-light">
            {formatDate(new Date())}
          </p>
          <Controller
            control={control}
            name="payableTo"
            render={({
                       field: {onChange, value},
                     }) => (
              <>
                <div className="fds-app font-light font-sm paymentRadioGroup">
                  <RadioGroup
                    legend={"Payable to:"}
                    value={value || null}
                    onChange={(radioValue) => {
                      setPayableTo(radioValue as string)
                      onChange(radioValue);
                    }}
                    legendClassName={"text-black font-medium"}
                    // Unable to override legend color, open CXC issue: https://github.ford.com/AV-TaaS/cxc-components-web/issues/666
                  >
                    <Radio className={"mb-2"} value={"Ford Pro Charging"}>
                      Ford Pro Charging
                    </Radio>
                    <div className="text-xsCustom pl-12 -mt-2 mb-3 text-gray">
                      By selecting "Ford Pro Charging", you are authorizing Ford Credit to make a payment on your
                      behalf to Ford Pro Charging.
                    </div>
                    <Radio className={"mb-2"} value={"My Business"}>
                      My Business
                    </Radio>
                    <div className="text-xsCustom pl-12 -mt-2 mb-3 text-gray">
                      By selecting "My Business", you are authorizing your payment to be sent to the bank account
                      on
                      file for this account.
                    </div>
                  </RadioGroup>
                </div>
                {errors.payableTo && (
                  <p className="text-red-600 text-xs">{formatError(errors.payableTo)}</p>
                )}
              </>
            )}/>
          <label htmlFor="amountInput" className="font-med">
            Amount Requested
          </label>
          <div
            className={`mt-1 mb-4 relative rounded shadow-sm border p-2 w-full ${
              errors.amount ? "border-red-600" : "border-gray-400"
            }`}
          >
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-black sm:text-sm">$</span>
            </div>
            <input
              {...register("amount")}
              id="amountInput"
              type="text"
              onChange={(newValue) =>
                amountChangeHandler(newValue.target.value)
              }
              className="mt-1 mb-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full p-.5 pl-5 text-base"
            />
          </div>
          {errors.amount && (
            <p
              className="text-red-600 text-xs m-2">{formatError(errors.amount)}</p>
          )}
          <label htmlFor="documentUpload" className="font-med">
            Supporting Documents
          </label>
          <p
            className={"text-sm font-light mt-1 italic"}>
            {(watch("payableTo") === "My Business") ? "Required" : "Optional"}
          </p>
          <div className="mt-4 mb-6">
            <div
              {...getRootProps({
                className:
                  "flex flex-col h-40 w-5/5 border border-dashed border-gray-400 rounded-md bg-white items-center justify-center ",
              })}
            >
              <input {...getInputProps()} />
              <p>Drag and drop files here or</p>
              <br/>
              <span className="text-ford-blue">Select files</span>
            </div>
            <aside className="text-sm">
              <ul>{files}</ul>
            </aside>
            {errors.documents && (
              <p className="text-red-600 text-xs m-2">
                {formatError(errors.documents)}
              </p>
            )}
            <p className=" text-xs pt-2">
              * The total of all documents submitted must be less than 25MB.{" "}
            </p>
          </div>

          <div className={"font-light text-sm mb-8"}>Requests submitted after 5:00 p.m. EST will be processed
            the following business day.
          </div>
          <div className="fds-app flex space-x-8 items-center lg:w-1/2 m-2 relative h-16 lg:mb-32">
            <>
              <PrimaryButton
                title="Submit"
                onClick={handleSubmit(() => setConfirmationRedirect(true))}
                disabled={!amount || !payableTo || (payableTo === "My Business" && documents.length === 0)}
              />
              <SecondaryButton
                title={"Cancel"}
                onClick={() => navigate("/")}
              />
            </>

          </div>
        </form>
        <div className="mx-auto">
          <h3 className="font-medium text-base">Credit Overview</h3>
          <CreditOverview
            border={false}
            availableCredit={customerData.availableCredit}
            creditLine={customerData.currentCreditLine}
            currentPrincipalBalance={customerData.principalOutstanding}
            currentDue={customerData.totalPaymentAmount}
            className="lg:h-1/4 lg:w-4/5 "
          />
        </div>
      </div>
    </ViewContainer>
  );
}
