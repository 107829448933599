import Button from "../button/Button";

export default function FinSimpleHeader(): JSX.Element {
  return (
    <div className="flex lg:justify-between lg:items-center flex-col lg:flex-row justify-center mb-2">
      <h1
        className="text-4xl font-light lg:text-left text-center pt-1 text-gray-600 p-4 pt-4 ml-4 mt-4"
        data-cy={"headerTitle"}>
        Ford Pro FinSimple Non-Vehicle Financing
      </h1>
      <div className="text-left lg:flex lg:justify-end justify-center pt-4 px-4 text-lg">
        <Button variant="link" text="Log Out" className="text-ford-blue underline" onClick={() => {
          window.fma.logout()
        }}/>
      </div>
    </div>
  );
}
