export type ButtonProps = {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  variant?: "primary" | "secondary" | "link";
  submit?: boolean;
};

export default function Button(props: ButtonProps): JSX.Element {
  const { variant: type = "primary" } = props;

  switch (type) {
    case "primary":
      return <Primary {...props} />;
    case "secondary":
      return <Secondary {...props} />;
    case "link":
      return <Link {...props} />;
  }
}

function Primary({
  disabled,
  onClick,
  text,
  className = "",
  submit,
}: ButtonProps): JSX.Element {
  return (
    <button
      className={`bg-primary text-white hover:bg-secondary disabled:bg-gray-500 border px-5 py-1 rounded-2xl ${className}`}
      onClick={onClick}
      disabled={disabled}
      type={submit ? "submit" : "button"}
    >
      {text}
    </button>
  );
}

function Secondary({
  disabled,
  onClick,
  text,
  className = "",
}: ButtonProps): JSX.Element {
  return (
    <button
      className={`bg-secondary text-white hover:bg-primary border px-5 py-1 rounded-2xl ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
}

function Link({
  disabled,
  onClick,
  text,
  className = "",
}: ButtonProps): JSX.Element {
  return (
    <button
      className={`border-none rounded-2xl ${
        className === "" ? "px-7 py-2 text-black " : `${className}`
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
}
