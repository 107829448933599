import * as React from 'react';
import {Dialog, DialogContent} from '@cxc/react-fds-components';

export type DialogBox = {
  title: string;
  body: React.ReactNode;
  buttonLabel: string;
  alignment: "left" | "center";
  onClick?: () => void | undefined;
};

export default function ErrorDialog(props: DialogBox): JSX.Element {
  const [dialogOpen, setDialogOpen] = React.useState(true);

  const toggleDialog = () => setDialogOpen(!dialogOpen);

  return (
    <div className={'fds-app'}>
      <Dialog visible={dialogOpen} onRequestClose={toggleDialog} type={'prompt'}>
        <DialogContent
          alignment={props.alignment}
          title={props.title}
          body={props.body}
          buttonOneText={props.buttonLabel}
          buttonOneOnClick={props.onClick || toggleDialog}
        />
      </Dialog>
    </div>
  );
}
