import {useFetchForBlob} from "@fmcc/common-hooks";
import {useEffect} from "react";
import Button from "./common/button/Button";

type StatementDropdownProps = {
  date: string;
};

enum monthName {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}

export default function StatementDropdown({
                                          date,
                                        }: StatementDropdownProps): JSX.Element {
  const [requestStatus, getStatement, response] = useFetchForBlob<Blob>();
  const [year, month] = date.split("-");

  const handleClick = () => {
    getStatement(`${process.env.REACT_APP_SERVICES_URL}/v2/nva/statement/${date}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.fma.CATBundle?.access_token}`,
        "Content-type": "application/pdf",
      },
    });
  };

  useEffect(() => {
    if (requestStatus === "Resolved" && response) {
      window.open(URL.createObjectURL(response));
    }
  }, [requestStatus, response]);

  return (
    <li className={"py-2"}>
      <Button
        variant={"link"}
        text={`${monthName[Number(month)]} ${year}`}
        onClick={handleClick}
        className={"underline text-ford-blue"}
      />
    </li>
  );
}
