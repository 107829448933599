import {SecondaryButton} from "@cxc/react-fds-components";

export const CaliforniaPrivacy = () => {
  return (
    <>
      <div className={"container justify-center items-center flex flex-col w-full py-10 bg-[#F1F1F1] shadow-footer font-regular text-[#00142E] text-[16px]"}>
        <div className={"pt-4 text-[22px] text-[#00095B]"}>California Residents</div>
        <div className={"pt-4 text-[#4D4D4D]"}>
          Exercise your rights under the California Consumer Privacy Act{" "}
          <a
            className={"text-[#00095B] underline"}
            href="https://www.ford.com/help/privacy/ccpa/?ccpatype=deletemypi"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </div>
        <div className={"fds-app"}>
          <SecondaryButton
            className={"mt-4 hover:underline"}
            onClick={(e) => {
              e.preventDefault();
              window.open("https://www.ford.com/help/privacy/ccpa/?ccpatype=donotsellmypi");
            }}
            title={"Do Not Sell My Personal Information"}
          />
        </div>
      </div>
    </>
  );
};
