import React from "react";
import {ViewContainer} from "../../components/common/view-container/ViewContainer";
import checkmark from "../../assets/check-circle.svg";
import {PrimaryButton} from "@cxc/react-fds-components";
import {useNavigate} from "react-router-dom";

export default function Success(): JSX.Element {
  let navigate = useNavigate();

  return (
    <ViewContainer headerText="Request Funds">
      <div className="lg:p-4 lg:pt-2 p-2">
        <div className="grid grid-col-2">
          <span className="px-4">
            <img src={checkmark} alt="Check Box" className="w-24 mr-4"/>
          </span>
          <div className="mt-2.5 grid col-start-2">
            <p className="font-medium text-xlCustom text-ford-blue mb-2">Request for funds submitted successfully.</p>
            <p className="font-regular mb-8">
              Please allow up to two business days for changes to be reflected in your account
            </p>
            <p className="text-sm font-light mt-2">
              Requests submitted after 5:00 p.m. EST will be processed the following business day.
            </p>
          </div>
        </div>

        <div className="fds-app p-2 mt-8 mb-44 w-full">
          <PrimaryButton
            className="pr-6"
            title="Done"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </div>
    </ViewContainer>
  );
}
