import React from "react";
import {ViewContainer} from "../../components/common/view-container/ViewContainer";
import {PrimaryButton} from "@cxc/react-fds-components";
import {useNavigate} from "react-router-dom";
import checkmark from "../../assets/check-circle.svg";

export default function MakePaymentConfirmation(): JSX.Element {
  let navigate = useNavigate();

  return (
    <ViewContainer headerText="Make Principal-Only Payment">
      <div className="lg:p-4 lg:pt-2 p-2">
        <div className="grid grid-col-2">
          <span className="px-4">
            <img src={checkmark} alt="Check Box" className="w-24 mr-4"/>
          </span>
          <div className="mt-2.5 grid col-start-2">
            <p className="font-medium text-xlCustom text-ford-blue mb-2">Thank you for your payment.</p>
            <p className="font-regular mb-8">
              Your principal-only payment was submitted successfully. Please allow up to two business days for changes
              to be reflected in your account.
            </p>
            <p className="text-sm font-light mt-2">
              Payments submitted after 5:00 pm Eastern Time will be processed the following business day.
            </p>
          </div>
        </div>
        <div className="fds-app flex lg:w-1/2 mt-20 relative h-16 lg:mb-32">
          <PrimaryButton
            className="mr-6"
            title="Done"
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    </ViewContainer>
  );
}
