import {useEffect} from "react";
import {useAuth} from "../context/AuthContext";
import {CustomerData} from "../types/CustomerData";
import {useFetch} from "@fmcc/common-hooks";

export default function useAuthenticatedCustomerData() {
  const [customerRequestStatus, fetchCustomerData, customerData] =
    useFetch<CustomerData>();

  const {isAuthenticated} = useAuth();

  useEffect(() => {
    if (isAuthenticated && customerRequestStatus === "NotAsked") {
      try {
        fetchCustomerData(`${process.env.REACT_APP_SERVICES_URL}/v2/nva/customer`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${window.fma.CATBundle?.access_token}`,
          },
        });
      } catch (error) {
        console.log("error fetching: ", error);
      }
    }
  }, [isAuthenticated, fetchCustomerData, customerRequestStatus]);

  return {customerRequestStatus, customerData};
}
