import styles from './Footer.module.scss'
import fordLogo from '../../../assets/ford-logo.svg';
import {SecondaryButton} from "@cxc/react-fds-components";
import {CaliforniaPrivacy} from "./CaliforniaPrivacy";

export const Footer = () => {
  return (
    <>
      <CaliforniaPrivacy/>
      <div className={styles.creditFooterContainer}>
        <span>Ford Credit</span>
      </div>
      <div className={styles.legalFooterContainer}>
        <img src={fordLogo} alt="Ford Logo" className={styles.logo}/>
        <span className={styles.copyright}>&copy; {new Date().getFullYear()} Ford Motor Company</span>
        <ul className={styles.legalLinkContainer}>
          <li>
            <a href="https://www.ford.com/help/sitemap/" target="_blank" rel="noopener noreferrer">Site Map</a>
          </li>
          <li>
            <a href="https://www.ford.com/help/glossary/" target="_blank" rel="noopener noreferrer">Glossary</a>
          </li>
          <li>
            <a href="https://www.ford.com/finance/customer-support/#contact" target="_blank" rel="noopener noreferrer">Site
              Feedback</a>
          </li>
          <li>
            <a href="https://www.ford.com/finance/customer-support/#contact" target="_blank" rel="noopener noreferrer">Contact
              Us</a>
          </li>
          <li>
            <a href="https://www.ford.com/help/contact/" target="_blank" rel="noopener noreferrer">Accessibility</a>
          </li>
          <li>
            <a href="https://www.ford.com/finance/terms-and-conditions/" target="_blank"
               rel="noopener noreferrer">Terms &amp; Conditions</a>
          </li>
          <li>
            <a href="https://www.ford.com/finance/privacy/" target="_blank" rel="noopener noreferrer">Privacy Notice</a>
          </li>
          <li>
            <SecondaryButton title={"Cookie Settings"}
                             className="text-primary hover:text-secondary text-left cursor-pointer optanon-toggle-display"
                             target="_blank"
            />
          </li>
          <li>
            <a href="https://info.evidon.com/pub_info/21090?v=1&nt=0&nw=false" target="_blank" rel="noopener noreferrer">
              Interest Based Ads
            </a>
          </li>
          <li>
            <a href="https://www.ford.com/finance/customer-support/#contact"
               target="_blank"
               rel="noopener noreferrer"
               className={styles.nycLink}>
              NYC Language Assistance
            </a>
          </li>
          <li>
            <a href="https://www.ford.com/help/privacy/ccpa/"
               target="_blank" rel="noopener noreferrer">Your Privacy Choices</a>
            <svg version="1" viewBox="0 0 730 350" className={"ml-2 h-4 text-[#06f] inline"}>
              <path
                d="M158.8 2.1C82.8 9.5 20.1 65.8 5 140c-9.4 45.9-.2 93 25.4 131.5 25.9 38.8 63.5 64.2 110.3 74.6 9.6 2.1 12.2 2.2 107.3 2.9 136.1 1.1 286.7 1.1 310.5 0 33.2-1.4 56-7.6 81.9-22.3 43.7-24.8 74.4-67.4 85-117.8 9.1-43.4-.6-91.7-26.3-130.1-26.5-39.6-66.3-65.7-114.4-75l-11.2-2.2-203-.2c-111.7-.1-206.9.2-211.7.7zm247.9 31.7c-.5 2.5-74.9 280-76.3 284.5-.5 1.7-4.6 1.8-87.7 1.4-85-.3-87.4-.4-96.4-2.5C117 310.4 94.2 298.1 74 278c-23.1-22.8-36.7-49.5-41.6-81.5-2.1-13.4-1.4-38.4 1.4-51 9.7-43.5 37.7-79.6 76.9-99.3 13.6-6.9 28.9-11.6 47.3-14.5 1.4-.2 58-.5 125.9-.6l123.4-.1-.6 2.8zm95.1 91.5l28.7 28.7 29-29 29.1-29h5.5c13.1 0 20 12.3 13 23.1-1.6 2.4-14.6 16.1-29 30.4l-26.1 26 28.9 29c26.6 26.7 29 29.3 29.6 33.2 1.7 10.8-8.3 20-18.9 17.2-3.5-.9-8.5-5.4-32.4-29.2L531 197.5l-28.3 28.2c-15.5 15.4-29.2 28.6-30.4 29.2-1.2.6-4.2 1.1-6.6 1.1-10.2 0-17.4-10.8-13.6-20.7.5-1.5 13.7-15.5 29.1-31.1l28.2-28.3-27.6-27.7c-15.2-15.2-28.3-29.1-29.2-30.9-3.3-6.6-1-14.8 5.4-19.1 2.9-2 4.4-2.3 9.3-2l5.8.4 28.7 28.7z"
                fill="currentcolor"
              ></path>
              <path
                d="M291 100.8c-2.4 1.2-18 18.8-50.2 56.6-25.7 30.2-47.6 55.9-48.7 57l-2.1 2-24.2-24.1c-20.5-20.4-24.9-24.2-28-24.8-12.1-2.3-21.1 8.6-16.7 20.2 1.2 3.2 58 60.7 62.2 62.9 1.6.8 4.5 1.6 6.6 1.7 8.8.3 8.4.7 64.8-65.5 29-34.1 53.8-63.9 55.1-66.1 2.7-4.9 2.7-7.9.2-13.5-1.5-3.3-2.9-4.7-6.2-6.2-5.3-2.4-8.3-2.5-12.8-.2z"
                fill="currentcolor"
              ></path>
            </svg>
          </li>
        </ul>
      </div>
    </>
  )
}
