import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";

type LocationState = {
  from: {
    pathname: string;
  };
};

export default function Login(): JSX.Element {
  let navigate = useNavigate();
  const {state: redirectTo}: { state: LocationState } = useLocation();
  const {login: signIn, isAuthenticated} = useAuth();

  const defaultRedirect = {from: {pathname: "/"}};
  const {from} = redirectTo || defaultRedirect;

  if (isAuthenticated) {
    navigate(from.pathname);
  } else {
    signIn();
  }

  return <></>;
}
