import {yupResolver} from "@hookform/resolvers/yup";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {useFetch} from "@fmcc/common-hooks";
import TermsAndConditions from "../../components/TermsAndConditions";
import {PrimaryButton} from "@cxc/react-fds-components";
import {formatError} from "../../common-ts/Formats";

const schema = yup.object().shape({
  accountNum: yup
    .number()
    .typeError("Must be a valid account number")
    .required("Please enter account number"),
  accountNumConfirm: yup
    .number()
    .typeError("Must re-enter valid account number")
    .oneOf([yup.ref("accountNum")], "Account numbers must match")
    .required("Please re-enter account number"),
  terms: yup
    .boolean()
    .oneOf([true], "You must accept the terms and conditions")
    .required(),
});

export default function AccountLink(): JSX.Element {
  const [status, addGdmsFmaAccount] = useFetch<string>();
  const [dealerFinanceLogInError, setDealerFinanceLogInError] =
    useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: {errors, isValid},
  } = useForm({mode: "all", resolver: yupResolver(schema)});

  useEffect(() => {
    switch (status) {
      case "Resolved":
        window.location.replace(`${process.env.REACT_APP_APPLICATION_URL}`)
        break;
      case "Rejected":
        setDealerFinanceLogInError(true);
        setValue('terms', false)
        break;
      case "Unauthorized":
        window.fma.logout();
        break;
    }
  }, [status, setValue]);

  const submitForm = () => {
    let formData = new FormData();
    const isTestApplication = window.localStorage.getItem("test_application") === "true";
    if (isTestApplication) {
      formData.append("testApplication", "true");
    }
    formData.append("dealerFinanceAccountNumber", getValues("accountNum"));
    addGdmsFmaAccount(`${process.env.REACT_APP_SERVICES_URL}/v2/nva/customer`, {
      body: formData,
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.fma.CATBundle?.access_token}`,
      },
    });
  };

  return (
    <div className="p-8 shadow-xl border bg-white pt-4">
      <h1 className="text-headerTextCustom py-8 pl-7">Link Your Account</h1>
      <p className="text-lg p-2 ml-5">
        In order to link your Ford Pro FinSimple Non-Vehicle Financing account,
        please enter your account number.
      </p>
      <p className="text-lg p-2 ml-5">
        You can find your account number in your Depot Charging Enrollment
        Email. Your account number is a 14 digit number, beginning with 0000.
      </p>
      <p className="text-lg p-2 ml-5">
        For questions please call <strong>615-315-2023</strong>.
      </p>
      <form className="mt-4">
        <div className=" flex flex-row justify-left mx-5">
          <div className="flex flex-col w-full md:w-1/3 pb-1 mb-6">
            <label htmlFor="accountNum" className="text-base pt-2 ml-2">
              Account Number
            </label>
            <div className="flex">
              <input
                {...register("accountNum")}
                id="accountNum"
                className={`border-2 p-1 ml-1 w-4/5 ${
                  errors.accountNum ? "border-red-600" : "border-gray-300"
                }  `}
                type="text"
              />
            </div>
            {errors.accountNum && (
              <>
                <p className="text-red-600 text-xs m-2">
                  {formatError(errors.accountNum)}
                </p>
              </>
            )}
          </div>
          <div className="flex flex-col w-full md:w-1/3">
            <label htmlFor="accountNumConfirm" className="mr-2 text-base pt-2">
              Re-enter Account Number
            </label>
            <input
              {...register("accountNumConfirm")}
              id="accountNumConfirm"
              className={`border-2 border-gray-300 p-1 w-4/5  ${
                errors.accountNumConfirm ? "border-red-600" : "border-gray-300"
              } `}
              type="text"
            />
            {errors.accountNumConfirm && (
              <p className="text-red-600 text-xs m-2 ">
                {formatError(errors.accountNumConfirm)}
              </p>
            )}
          </div>
        </div>
        <TermsAndConditions/>

        <div className="content-center mx-5 bg-gray-200 py-3 flex items-center">
          <input
            {...register("terms")}
            id="terms"
            className={`w-5 h-5 ml-3 appearance-none ${
              getValues("terms")
                ? "bg-checkbox-selected"
                : "bg-checkbox-unselected"
            } ${errors.terms ? "border-red-600" : "border-gray-300"}  `}
            type="checkbox"
          />
          <label htmlFor="terms" className="text-base ml-2 text-ford-blue">
            I agree to the Ford Pro FinSimple Non-Vehicle Financing Terms &
            Conditions
          </label>
        </div>
        {errors.terms && (
          <p className="text-red-600 text-xs ml-5 m-2">
            {formatError(errors.terms)}
          </p>
        )}

        <div className="fds-app mt-6 p-2 ml-5">
          <PrimaryButton
            className="pr-6"
            title="Link Accounts"
            onClick={handleSubmit(() => submitForm())}
            disabled={!isValid}
          />
        </div>
        <div>
          <p
            className={`text-sm text-red-600 m-2 pb-5 ml-5 ${
              !dealerFinanceLogInError ? "hidden" : "visible"
            }`}
          >
            There is a problem logging into your account. Please try again.
            If you continue to experience problems, please contact us at: <br/>
            <strong>615-315-2023.</strong>
          </p>
        </div>
      </form>
    </div>
  );
}
