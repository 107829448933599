import React, {createContext, useContext, useState} from "react";
import {Navigate} from "react-router-dom";

declare global {
  interface Window {
    fma: {
      login: () => void;
      logout: () => void;
      errors?: any;
      isAuthenticated: boolean;
      CATBundle?: {
        access_token: string;
      };
    };
  }
}

type AuthContext = {
  isAuthenticated: boolean | undefined;
  login: () => void;
  logout: () => void;
};

const authContext = createContext<AuthContext>({
  isAuthenticated: false,
  login: () => {
  },
  logout: () => {
  },
});

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({children}: AuthProviderProps) {
  const auth = useProvidedAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function useProvidedAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(window?.fma?.isAuthenticated);
  document.body.addEventListener("fma_authenticated", () => {
    setIsAuthenticated(true);
  });

  document.body.addEventListener("fma_unauthenticated", () => {
    setIsAuthenticated(false);
  });

  document.body.addEventListener("fma_logout", () => {
    setIsAuthenticated(false);
  });

  document.body.addEventListener("fma_errors", (event) => {
    if (window.fma.errors !== null && window.fma.errors.store && window.fma.errors.store.length > 0) {
      return (
        <Navigate
          to={"/login-error"}
        />
      );
    }
  });

  const login = () => {
    window.fma.login();
  };

  const logout = () => {
    window.fma.logout();
  };

  return {
    isAuthenticated,
    login,
    logout,
  };
}
