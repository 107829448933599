export default function Loader() {
  return (
    <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
      <div className="lg:h-12 bg-primary md:h-36 w-full object-cover object-center"></div>
      <div className="p-6 lg:mt-12">
        <div className="lg:mb-8">
          <p className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></p>
          <p className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></p>
        </div>
        <div className="lg:mb-8">
          <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
          <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
        </div>
        <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
        <div className="flex items-center flex-wrap lg:mt-16">
          <span className="bg-primary h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0"></span>
          <span className="bg-primary w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span>
        </div>
      </div>
    </div>
  );
}
