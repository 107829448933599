import React from "react";

export type CardContainerProps = {
  children: React.ReactNode;
};

export default function CardContainer(props: CardContainerProps): JSX.Element {
  return (
    <div
      className="xl:col-start-2 xl:row-span-3 xl:col-span-3 xl:row-start-1 xl:grid-cols-2 gap-y-5 gap-x-10 p-8 shadow-xl bg-white ml-2">
      {props.children}
    </div>
  );
}
