export type NavButtonProps = {
  text: string;
  onClick?: () => void;
  className?: string;
  active?: boolean;
};

export default function NavButton({onClick, text, active}: NavButtonProps): JSX.Element {
  return (
    <button
      className={` w-full h-16 shadow-lg  ${text === "Account Dashboard" ? "mb-2" : "my-2"} font-medium ${active ? "bg-primary text-white" : "bg-white text-primary"} `}
      onClick={onClick}
      type={"button"}
    >
      <div
        className={`${active ? "text-white" : "text-primary"} flex fill-current items-center `}>
        <span className={"w-full text-left pl-6 text-lg"}>
          {text}
        </span>
      </div>
    </button>
  );
}
