import {BrowserRouter, Navigate, Route, Routes, useLocation} from "react-router-dom";
import {CustomHeader} from "@fmcc/commercial-header";
import ConfirmRequest from "../pages/request-funds/ConfirmRequest";
import AccountDashboard from "../pages/home/AccountDashboard";
import AccountLink from "../pages/account-link/AccountLink";
import useCustomerDataIfAuthenticated from "../hooks/useCustomerDataIfAuthenticated";
import LoadCustomerData from "../pages/load-customer-data/LoadCustomerData";
import LoginError from "../pages/login-error/loginError";
import Login from "./login/Login";
import Success from "../pages/request-funds/Success";
import RequestFunds from "../pages/request-funds/RequestFunds";
import MakePrincipalOnlyPayment from "../pages/make-a-payment/MakePrincipalOnlyPayment";
import MakePaymentConfirmation from "../pages/make-a-payment/MakePaymentConfirmation";
import TermsAndConditions from "./TermsAndConditions";
import {StatementProvider} from "../context/StatementContext";
import CurrentStatement from "../pages/current-statement/CurrentStatement";
import {Footer} from "./common/footer/Footer";
import {useAuth} from "../context/AuthContext";


export default function AuthenticatedRouter() {
  const {customerRequestStatus, customerData} = useCustomerDataIfAuthenticated();

  return (
    <BrowserRouter>
      <div className={"flex flex-col h-screen justify-between text-base"}>
        <header>
          <CustomHeader languageOptions={[]}/>
        </header>
        <main className={"mb-auto"}>
          <Routes>
            <Route path="/login" element={<Login/>}/>

            <Route path="/login-error" element={<LoginError/>}/>

            <Route element={
              <AuthenticatedRoute>
                <LoadCustomerData status={customerRequestStatus} customerData={customerData!}>
                  <ConfirmRequest customerData={customerData!}/>
                </LoadCustomerData>
              </AuthenticatedRoute>
            } path={"/confirm"}/>

            <Route element={
              <AuthenticatedRoute>
                <Success/>
              </AuthenticatedRoute>
            } path={"/success"}/>

            <Route element={
              <AuthenticatedRoute>
                <LoadCustomerData status={customerRequestStatus} customerData={customerData!}>
                  <AccountDashboard customerData={customerData!}/>
                </LoadCustomerData>
              </AuthenticatedRoute>
            } path={"/"}/>

            <Route element={
              <AuthenticatedRoute>
                <LoadCustomerData status={customerRequestStatus} customerData={customerData!}>
                  <RequestFunds customerData={customerData!}/>
                </LoadCustomerData>
              </AuthenticatedRoute>
            } path={"/request"}/>

            <Route element={
              <AuthenticatedRoute>
                <LoadCustomerData status={customerRequestStatus} customerData={customerData!}>
                  <MakePrincipalOnlyPayment customerData={customerData!}/>
                </LoadCustomerData>
              </AuthenticatedRoute>
            } path={"/payment"}/>

            <Route element={
              <AuthenticatedRoute>
                <MakePaymentConfirmation/>
              </AuthenticatedRoute>
            } path={"/payment-confirmation"}/>

            <Route element={
              <AuthenticatedRoute>
                <TermsAndConditions forDashBoard={true}/>
              </AuthenticatedRoute>
            } path={"/terms-conditions"}/>

            <Route element={
              <AuthenticatedRoute>
                {customerData?.dealerFinanceAccountNumber ? (
                  <LoadCustomerData status={customerRequestStatus} customerData={customerData!}>
                    <AccountDashboard customerData={customerData!}/>
                  </LoadCustomerData>
                ) : (
                  <AccountLink/>
                )}
              </AuthenticatedRoute>
            } path={"/account-link"}/>

            <Route element={
              <StatementProvider>
                <AuthenticatedRoute>
                  <CurrentStatement/>
                </AuthenticatedRoute>
              </StatementProvider>
            } path={"/statement"}/>

          </Routes>
        </main>
        <footer>
          <Footer/>
        </footer>
      </div>
    </BrowserRouter>
  );
}

type AuthenticatedRouteProps = {
  children: React.ReactElement;
  [key: string]: any;
};

const AuthenticatedRoute = (
  {
    children,
  }
    : AuthenticatedRouteProps) => {
  let {isAuthenticated} = useAuth();
  const location = useLocation();

  if (isAuthenticated === undefined) {
    return <></>;
  }
  if (isAuthenticated) {
    return children;
  }
  return (
    <div>
      <Navigate
        to={"/login"}
        state={{from: location}}
      />
    </div>
  );
}
