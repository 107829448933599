import NavigationBar from "../../nav-bar/NavigationBar";
import CardContainer from "../card-container/CardContainer";
import FinSimpleHeader from "../header/FinSimpleHeader";
import React from "react";

type Props = {
  children: React.ReactNode;
  headerText?: string;
};

export function ViewContainer({children, headerText}: Props) {
  return (
    <div className="container bg-ford-gray-V pl-6 pr-10 pb-20">
      <FinSimpleHeader/>
      <div className="grid grid-cols-1 xl:grid-cols-4 auto-rows-min gap-y-5 gap-x-10 p-4">
        <div className="col-start-1 row-span-full">
          <NavigationBar/>
        </div>
        <CardContainer>
          {headerText ? (
            <>
              <h2 className="m-2 text-headerTextCustom font-regular border-gray-400 lg:px-4"
                  data-cy={"viewContainerHeader"}>
                {headerText}
              </h2>
              <hr className={"my-4 border-t-2 border-gray-300  "}/>
            </>
          ) : (
            <></>
          )}
          {children}
        </CardContainer>
      </div>
    </div>
  );
}
