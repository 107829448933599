import {formatIsoDateString, formatMoney} from "../../common-ts/Formats";

export type AutoPayBoxProps = {
  paymentAmount: number;
  paymentDate: string;
  className?: string;
};

export default function AutoPayBox({paymentAmount, paymentDate, className}: AutoPayBoxProps): JSX.Element {
  return (
    <div
      className={`${className} box-border bg-ford-gray-V p-4 mt-5`}>
      <h3 className="text-ford-blue text-lg font-medium">You are enrolled in autopay.</h3>
      <p className="font-light text-left">
        {`Your next monthly payment of ${formatMoney(paymentAmount)} will be deducted on ${formatIsoDateString(paymentDate)}.`}
      </p>
    </div>
  );
}
